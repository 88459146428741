import React from 'react'
import styled from 'styled-components'
// import HeadingMain from '../components/HeadingMain'
import StyledLink from '../components/styles/StyledLink'
import { graphql } from 'gatsby'

import SEO from '../components/SEO'

const Container = styled.div`
  margin-top: ${p => p.theme.marginToTop};
  margin-bottom: 120px;
  max-width: ${p => p.theme.maxPageWidth};
  margin-left: auto;
  margin-right: auto;
  padding-left: ${p => p.theme.paddingOnSides};
  padding-right: ${p => p.theme.paddingOnSides};
`

const TopHeading = styled.h1`
  font-size: 38px;
  font-family: Montserrat, sans-serif;
  color: ${p => p.theme.textColorDark};
  font-weight: 600;
  margin-top: ${p => p.theme.marginToTop};
  margin-bottom: 60px;
  line-height: 1.2;
`

const List = styled.ul``

const ListItem = styled.li`
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  color: ${p => p.theme.textColorDark};
  letter-spacing: 1.2px;
`

const SubList = styled.ul`
  margin-top: 12px;
  margin-bottom: 40px;
  margin-left: 50px;
`

const SubListItem = styled.li`
  text-indent: ${p => (p.twoDigitNumber ? '-40px' : '-30px')};
  margin-bottom: 14px;
`

const SubSubList = styled.ul`
  margin-left: 44px;
`

const SubSubListItem = styled.li`
  text-indent: -44px;
  margin-bottom: 14px;
`

const AGB = props => {
  let relativePath = undefined
  if (
    props.data &&
    props.data.markdownRemark &&
    props.data.markdownRemark.frontmatter &&
    props.data.markdownRemark.frontmatter.preislistePdf &&
    props.data.markdownRemark.frontmatter.preislistePdf.relativePath
  ) {
    relativePath = props.data.markdownRemark.frontmatter.preislistePdf.relativePath
  }
  return (
    <>
      <SEO title="Allgemeine Geschäftsbedingungen" />
      <Container>
        {/* <HeadingMain mainHeading="Allgemeine Geschäftsbedingungen" /> */}
        <TopHeading> Allgemeine Geschäfts&shy;bedingungen</TopHeading>
        <List>
          <ListItem>1. GELTUNGSBEREICH</ListItem>
          <SubList>
            <SubListItem>
              1.1. Diese Allgemeinen Geschäftsbedingungen gelten für den Verkauf und die Lieferung von Waren durch die
              Weingut Göschl GesbR. Der Firmensitz und die Geschäftsadresse sind 7122 Gols, Am Kanal 4. Die Allgemeinen
              Geschäftsbedingungen können von der Weingut Göschl GesbR jederzeit abgeändert werden und gelten in der zum
              Zeitpunkt der Bestellung des Kunden aktuellen Fassung.
            </SubListItem>
            <SubListItem>
              1.2. Abweichende Geschäftsbedingungen haben keine Gültigkeit, es sei denn, die Weingut Göschl GesbR hat
              diesen vor Annahme der Bestellung schriftlich oder per E-Mail zugestimmt.
            </SubListItem>
            <SubListItem>
              1.3. Mit der Abgabe einer Bestellung erklärt sich der Kunde mit diesen Allgemeinen Geschäftsbedingungen
              einverstanden.
            </SubListItem>
          </SubList>
          <ListItem>2. VERTRAGSABSCHLUSS</ListItem>
          <SubList>
            <SubListItem>
              2.1. Die Bestellung durch den Kunden stellt ein Angebot an die Weingut Göschl GesbR zum Abschluss eines
              Kaufvertrages dar. Nach der Bestellung erhalten Sie eine Email, das den Eingang Ihrer Bestellung bei uns
              bestätigt, erst diese stellt den Kaufvertrag dar.
            </SubListItem>
            <SubListItem>
              2.2. Die Weingut Göschl GesbR ist berechtigt, eine Bestellung auch ohne Angabe von Gründen abzulehnen.
            </SubListItem>
          </SubList>
          <ListItem>3. PREISE UND VERSANDKOSTEN</ListItem>
          <SubList>
            <SubListItem>
              3.1. Die angebotenen Preise der Waren verstehen sich als Bruttopreise in Euro und beinhalten bei
              Lieferungen im Inland sämtliche gesetzlichen Steuern und Abgaben. Die angebotenen Preise enthalten jedoch
              nicht allfällige Verpackungs- und Versandkosten.
            </SubListItem>
            <SubListItem>
              3.2. Bei Lieferungen außerhalb Österreichs können zusätzliche Zölle und/oder Abgaben anfallen,
              einschließlich allfälliger Ein- bzw. Ausfuhrabgaben und allfälliger Verbrauchssteuern, die zu einer
              Änderung der angebotenen Bruttopreise führen können. Derartige Zölle und/oder Abgaben sind in der
              jeweiligen gesetzlichen Höhe vom Käufer zu tragen.
            </SubListItem>
            <SubListItem>
              3.3. Die jeweils angegebenen Preise sind - soweit nicht im Einzelfall ausdrücklich etwas anderes angegeben
              ist - stets unverbindlich. Für Nachbestellungen sind die Preise ebenfalls unverbindlich.
            </SubListItem>
            <SubListItem>
              3.4. Es gelten die Preise vom Tag der Bestellung bzw. laut der aktuellen Preisliste, die tagesaktuell auf
              der Website abrufbar ist:
              <br />
              {relativePath && (
                <StyledLink as="a" target="_blank" rel="noopener noreferrer" href={`/assets/${relativePath}`}>
                  <strong>Preisliste</strong>
                </StyledLink>
              )}
            </SubListItem>
            <SubListItem>
              3.5. Sollten sich die Kosten bis zum Zeitpunkt der Abgabe einer Bestellung verändern, so ist die Weingut
              Göschl GesbR berechtigt, die Preise entsprechend anzupassen. Die verbindlichen Preise werden im Onlineshop
              nach korrekter Auswahl im Warenkorb angezeigt. Bei einer per Post, Telefon oder E-Mail abgegebenen
              Bestellung wird der verbindliche Preis in der von der Weingut Göschl GesbR schriftlich (per E-Mail)
              abgegebenen Bestellbestätigung ausgewiesen.
            </SubListItem>
            <SubListItem>
              3.6. Alle durch den Versand entstehenden Kosten trägt der Käufer. Die Kosten der Zustellung werden bei
              Bestellung im Onlineshop nach korrekter Auswahl im Warenkorb angezeigt. Bei einer per Post, Telefon oder
              E-Mail abgegebenen Bestellung in der von der Weingut Göschl GesbR schriftlich (per E-Mail) abgegebenen
              Bestellbestätigung ausgewiesen.
            </SubListItem>
            <SubListItem>
              3.7. Ab einem Bruttowarenpreis von € 140,- übernimmt die Weingut Göschl GesbR die Kosten der
              Standardzustellung innerhalb Österreichs.
            </SubListItem>
          </SubList>
          <ListItem>4. ZAHLUNG UND ANZAHLUNGEN</ListItem>
          <SubList>
            <SubListItem>
              4.1. Der Kaufpreis ist bei Vertragsabschluss, spätestens bei Lieferung der Ware, fällig. Dies gilt auch
              für Teillieferungen, bei denen der Kaufpreis für die jeweils gelieferten Teilmengen zu bezahlen ist.
            </SubListItem>
            <SubListItem>
              4.2. Ist eine Lieferung durch Zustellung vereinbart, so werden die Lieferkosten gesondert verrechnet. Das
              Entgelt dafür wird bei Lieferung der Ware fällig.
            </SubListItem>
            <SubListItem>
              4.3. Gerechtfertigte Reklamationen berechtigen den Käufer nicht zur Zurückbehaltung des gesamten, sondern
              lediglich eines angemessenen Teiles des Rechnungsbetrages. Bei Geschäften mit Verbrauchern kann dieser
              seine Zahlung zur Gänze dann verweigern, wenn die Lieferung durch die Weingut Göschl GesbR nicht
              vertragsgemäß erbracht hat oder die Erbringung durch deren schlechte Vermögensverhältnisse, die dem
              Verbraucher zur Zeit der Vertragsschließung weder bekannt waren noch bekannt sein mussten, gefährdet ist.
            </SubListItem>
            <SubListItem>
              4.4. Ist der Käufer mit der Zahlung oder sonstigen Leistung in Verzug, so ist die Weingut Göschl GesbR
              berechtigt:
            </SubListItem>
            <SubSubList>
              <SubSubListItem>
                4.4.1. Verzugszinsen von 6% über dem Basiszinssatz der Oesterreichischen Nationalbank, mindestens jedoch
                10% pro Jahr inkl. Umsatzsteuer zu verrechnen, und
              </SubSubListItem>
              <SubSubListItem>
                4.4.2. bei Nichteinhaltung einer angemessenen Nachfrist vom Vertrag zurückzutreten, sowie
              </SubSubListItem>
              <SubSubListItem>
                4.4.3. vom Käufer die entstehenden Mahn- und Inkassospesen, soweit sie zur zweckentsprechenden
                Rechtsverfolgung notwendig sind und in einem angemessenen Verhältnis zur betriebenen Forderung stehen,
                begehren.
              </SubSubListItem>
            </SubSubList>
            <SubListItem>
              4.5. Eingeräumte Rabatte sind mit dem Eingang der vollständigen Zahlung aufschiebend bedingt. Bei
              Bezahlung mit Gutscheinen kann für allfällige Restbeträge keine Gutschrift ausgestellt werden. Die
              Einlösefrist von Gutscheinen richtet sich nach dem auf den ausgestellten Gutscheinen angegebenen Datum.
            </SubListItem>
            <SubListItem>
              4.6. Rabatte gelten nicht auf bereits reduzierte Waren. Rabatte und Aktionsangebote sind nur bei der
              Abnahme von üblichen Haushaltsmengen anwendbar.
            </SubListItem>
          </SubList>
          <ListItem>5. GEFAHRENÜBERGANG</ListItem>
          <SubList>
            <SubListItem>
              5.1. Bei Selbstabholung der Ware durch den Käufer gehen Nutzung und Gefahr spätestens mit der Übergabe der
              Waren auf den Käufer über.
            </SubListItem>
            <SubListItem>
              5.2. Bei Zustellung an einen Unternehmer bestimmt die Weingut Göschl GesbR – mangels besonderer
              Vereinbarung mit dem Käufer – Transportart und Transportweg. Mit der Übergabe der Ware gehen Nutzung und
              Gefahr von der Weingut Göschl GesbR an den Transporteur über. Die Eindeckung der Lieferung durch eine
              Transportversicherung über einen Warenwert von EUR 250 ,- (bei DPD Versand) bzw. von EUR 1.453,46 (bei
              Versand mit Bahn-Express) hinaus erfolgt nur über ausdrückliche Weisung des Käufers und auf dessen Kosten.
            </SubListItem>
            <SubListItem>
              5.3. Bei Zustellung an einen Verbraucher gehen Nutzung und Gefahr auf den Käufer über, sobald die Ware an
              diesen oder an einen von diesem bestimmten, vom Transporteur verschiedenen Dritten, abgeliefert wird. Hat
              aber der Verbraucher selbst den Beförderungsvertrag geschlossen, ohne eine von der Weingut Göschl GesbR
              vorgeschlagenen Auswahlmöglichkeit zu nützen, geht die Gefahr bereits mit Aushändigung der Ware an den
              Transporteur über.
            </SubListItem>
          </SubList>
          <ListItem>6. EIGENTUMSVORBEHALT</ListItem>
          <SubList>
            <SubListItem>
              {' '}
              6.1. Die gelieferte Ware bleibt bis zur vollständigen Bezahlung Eigentum der Weingut Göschl GesbR.
            </SubListItem>
          </SubList>
          <ListItem>7. RÜCKGABE DER WARE UND RÜCKTRITTSRECHT</ListItem>
          <SubList>
            <SubListItem>
              7.1. Ist der Käufer ein Verbraucher und hat er seine Bestellung per Post, Telefon, E-Mail oder über den
              Onlineshop aufgegeben, so kann er gemäß §§ 11 FAGG innerhalb von 14 Tagen ab dem Tag des Eingangs der Ware
              beim Käufer den Rücktritt vom Kaufvertrag erklären.
            </SubListItem>
            <SubListItem>
              7.2. Die Widerrufsfrist beginnt, sobald der Käufer oder ein von Ihm benannter Dritter die Ware übernommen
              hat. Im Falle eines Kaufvertrags über mehrere Waren beginnt die Widerrufsfrist, sobald der Käufer oder ein
              von ihm benannter Dritter die letzte Teilsendung, die letzte Ware oder das letzte Stück übernommen hat.
              Der Tag der Übernahme wird in den Fristenlauf nicht einbezogen. Samstage, Sonn- und Feiertage zählen zur
              Berechnung der Frist mit. Zur Wahrung der Rücktrittsfrist genügt die rechtzeitige Absendung der
              Rücktrittserklärung bzw. die Rücksendung der Ware.
            </SubListItem>
            <SubListItem>
              7.3. Um das Widerrufsrecht auszuüben, muss der Verbraucher der Weingut Göschl GesbR mittels einer
              eindeutigen Erklärung (z.B. per E-Mail oder postalisch) über den Entschluss, den Vertrag zu widerrufen,
              informieren. Zur Wahrung der Widerrufsfrist reicht es aus, die Mitteilung über die Ausübung des
              Widerrufsrechts vor Ablauf der Widerrufsfrist an folgende Geschäftsadresse zu senden:
              <br />
              <br />
              Weingut Göschl GesbR
              <br />
              7122 Gols
              <br />
              Am Kanal 4
              <br />
              E-Mail:{' '}
              <StyledLink style={{ whiteSpace: 'nowrap' }} as="a" href="mailto:office@weingut-goeschl.at">
                office@weingut-goeschl.at
              </StyledLink>
              <br />
              Tel:{' '}
              <StyledLink style={{ whiteSpace: 'nowrap' }} as="a" href="tel:+436644530988">
                0664/4530988
              </StyledLink>
              <br />
              <br />
            </SubListItem>
            <SubListItem>
              7.4. Wenn dieser Vertrag durch einen Verbraucher innerhalb der Widerrufsfrist ordnungsgemäß widerrufen
              wird, hat die Weingut Göschl GesbR von Ihnen erhaltene Zahlungen, einschließlich der Lieferkosten (mit
              Ausnahme der zusätzlichen Kosten, die sich daraus ergeben, dass der Verbraucher eine andere Art der
              Lieferung als die von der Weingut Göschl GesbR angebotene, günstigste Standardlieferung gewählt haben),
              unverzüglich und spätestens binnen vierzehn Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über
              Ihren Widerruf dieses Vertrags bei der Weingut Göschl GesbR eingegangen ist.
            </SubListItem>
            <SubListItem>
              7.5. Die Weingut Göschl GesbR kann die Rückzahlung verweigern, bis die Waren bei der Weingut Göschl GesbR
              eingelangt sind oder bis der Nachweis erbracht wird, dass die Waren zurückgesandt wurden, je nachdem,
              welches der frühere Zeitpunkt ist. Die Waren sind unverzüglich und in jedem Fall spätestens binnen 14
              Tagen ab dem Tag, an dem die Weingut Göschl GesbR über den Widerruf dieses Vertrags unterrichtet wurde, an
              die Weingut Göschl GesbR, 7122 Gols, Am Kanal 4 zurückzusenden oder zu übergeben. Die Frist ist gewahrt,
              wenn die Waren vor Ablauf der Frist von vierzehn Tagen versendet wurde. Der Verbraucher trägt die
              unmittelbaren Kosten sowie die Gefahr der Rücksendung der Waren und haftet für einen etwaigen Wertverlust
              der Waren, wenn dieser Wertverlust auf einen zur Prüfung der Beschaffenheit, Eigenschaften und
              Funktionsweise der Waren nicht notwendigen Umgang durch den Verbraucher zurückzuführen ist.
            </SubListItem>
          </SubList>
          <ListItem>8. BESTELLUNGEN, LIEFERFRIST, LIEFERHINDERNISSE, RÜCKTRITT VOM VERTRAG</ListItem>
          <SubList>
            <SubListItem>
              8.1. Die Weingut Göschl GesbR nimmt Aufträge per Telefon, E-Mail oder über den Onlineshop entgegen. Bei
              Auftragserteilung hat der Kunde einen gewünschten Liefertermin sowie einen Ersatztermin und den genauen
              Lieferort zu nennen. Der Kunde ist verpflichtet, zu diesen Terminen die ordnungsgemäße Übernahme der
              bestellten Ware am angegebenen Lieferort sicherzustellen. Die Weingut Göschl GesbR übermittelt dem Kunden
              eine schriftliche (per E-Mail) Bestellbestätigung mit allen relevanten Auftragsdaten.
            </SubListItem>
            <SubListItem>
              8.2. Im Fall der Nichtannahme von bestellter Ware ist die Weingut Göschl GesbR berechtigt, den Ersatz der
              dadurch entstandenen Mehraufwendungen, wie z.B. frustrierte Transportkosten, zu verlangen. Dies gilt
              nicht, wenn die Weingut Göschl GesbR ihre Leistungen nicht vertragsgemäß erbringt.
            </SubListItem>
            <SubListItem>
              8.3. Die Annahme von Bestellungen erfolgt unter Vorbehalt der Liefermöglichkeiten. Die Weingut Göschl
              GesbR behält sich vor, bei Überzeichnung eines Produktes den Bestellern auch geringere Mengen zuzuteilen.
              Wird die Lieferung oder die Einhaltung einer vereinbarten Lieferfrist durch von die Weingut Göschl GesbR
              nicht zu vertretende Umstände unmöglich, so erlischt die Lieferpflicht zu dem vorgesehenen Liefertermin.
              Die Weingut Göschl GesbR wird in solchen Fällen den Käufer unverzüglich kontaktieren, um einen
              Ersatztermin für die verhinderte Lieferung zu vereinbaren.
            </SubListItem>
            <SubListItem>
              8.4. Kann die Weingut Göschl GesbR dem Käufer keinen neuen Liefertermin gemäß Punkt 8.3. anbieten oder
              kann auch der neue Liefertermin aufgrund der Unmöglichkeit der Lieferung zum vereinbarten Termin aus durch
              nicht zu vertretenden Umständen nicht eingehalten werden, ist die Weingut Göschl GesbR berechtigt, vom
              Vertrag zur Gänze oder teilweise zurückzutreten, ohne schadenersatzpflichtig zu werden. Ebenso kann in
              diesen Fällen der Käufer vom Vertrag zurücktreten.
            </SubListItem>
            <SubListItem>
              8.5. Änderungen oder Stornierungen von Bestellungen durch den Käufer bedürfen grundsätzlich der
              Schriftform (einschließlich einer Mitteilung per E-Mail).
            </SubListItem>
            <SubListItem>
              8.6. Für Online-Gutscheine gelten zusätzlich die im Internet genannten Bedingungen.
            </SubListItem>
          </SubList>
          <ListItem>9. GEWÄHRLEISTUNG</ListItem>
          <SubList>
            <SubListItem>
              9.1. Gewährleistungsansprüche setzen voraus, dass Mängel der Weingut Göschl GesbR unverzüglich angezeigt
              werden, und zwar erkennbare Mängel sofort bei Übernahme, versteckte Mängel nach Entdeckung, und unter
              Vorlage der angebrochenen Ware und Originalrechnung.
            </SubListItem>
            <SubListItem>
              {' '}
              9.2. Ein Gewährleistungsanspruch ist in jedem Fall mit dem Kaufpreis der gelieferten und mangelhaften Ware
              begrenzt.
            </SubListItem>
            <SubListItem>
              9.3. Die Weingut Göschl GesbR erfüllt ihre Gewährleistungsverpflichtungen nach ihrer Wahl entweder durch
              Lieferung mangelfreier Ware, Verbesserung, Nachlieferung von Fehlmengen oder Rückabwicklung des Vertrags
              (d.h. Rückzahlung des Kaufpreises) innerhalb einer angemessenen Frist.
            </SubListItem>
            <SubListItem>
              9.4. Ist der Vertragspartner ein Verbraucher, so kann dieser im Falle eines Mangels unter Beachtung der
              gesetzlichen Bestimmungen zwischen Verbesserung, Austausch der Sache, angemessene Preisminderung oder
              Wandlung begehren.
            </SubListItem>
            <SubListItem>
              9.5. Geschmackliche Gründe, handelsübliche oder geringfügige, technisch bedingte Abweichungen der
              Qualität, Quantität, Farbe, Größe, des Gewichtes, der Ausrüstung oder des Design sowie altersgemäße
              Veränderungen von Geschmack, Farbe und Verpackung stellen weder Gewährleistungsmängel noch Nichterfüllung
              des Vertrages dar.
            </SubListItem>
            <SubListItem>
              9.6. Die Weingut Göschl GesbR haftet nicht für geschmackliche, farbliche, materialmäßige und mustermäßige
              Übereinstimmung oder sonstige Übereinstimmungsmerkmale von nachbestellter Ware. Entsprechendes gilt für
              nach Muster bestellte Ware, soweit sich die Abweichung in den handelsüblichen und technischen Grenzen
              hält.
            </SubListItem>
            <SubListItem>
              9.7. Nach Verkostung, Konsumation oder begonnener Ver- oder Bearbeitung der Ware ist jede Gewährleistung
              ausgeschlossen.
            </SubListItem>
            <SubListItem>
              9.8. Die Gewährleistungsfrist beträgt 2 Jahre ab dem Zeitpunkt des Gefahrenüberganges gemäß Pkt. 5.
            </SubListItem>
            <SubListItem>9.9. Für Geschäfte mit Verbrauchern sind die Punkte 9.6 bis 9.8 nicht anzuwenden.</SubListItem>
          </SubList>
          <ListItem>10. HAFTUNG FÜR SCHADENERSATZ</ListItem>
          <SubList>
            <SubListItem twoDigitNumber>
              10.1. Die Weingut Göschl GesbR haftet für Schäden nach den gesetzlichen Bestimmungen. Die Haftung für
              leicht fahrlässig zugefügte Schäden ist jedoch ausgeschlossen. Die Haftungsbeschränkung gilt nicht für
              Schäden aus der Verletzung des Lebens, des Körpers oder der Gesundheit von Menschen und für Ansprüche nach
              dem österreichischen Produkthaftungsgesetz.
            </SubListItem>
            <SubListItem twoDigitNumber>
              10.2. Unternehmer haben das Vorliegen von Vorsatz oder grober Fahrlässigkeit zur beweisen. Gegenüber
              Unternehmern ist die Haftung für entgangenen Gewinn und sonstiger Folgeschäden ausgeschlossen. Weiters ist
              ihnen gegenüber die Haftung mit 10% des Kaufpreises begrenzt. Der Ersatz von (Mangel-) Folgeschäden,
              sonstigen Sachschäden, Vermögensschäden und Schäden aus Ansprüchen Dritter gegen den Kunden ist gegenüber
              Unternehmern ebenfalls ausgeschlossen.
            </SubListItem>
            <SubListItem twoDigitNumber>
              10.3. Für Geschäfte mit Verbrauchern im ist Punkt 10.1 nicht anzuwenden.
            </SubListItem>
          </SubList>

          <ListItem>11. JUGENDSCHUTZ</ListItem>
          <SubList>
            <SubListItem twoDigitNumber>
              11.1. Die Abgabe und die Zustellung von Wein kann nur an Personen über 18 Jahren erfolgen.
            </SubListItem>
            <SubListItem twoDigitNumber>
              11.2. Mit Aufgabe der Bestellung versichert der Kunde, dass dieser bzw. der Empfänger der Ware älter als
              18 Jahre ist.
            </SubListItem>
          </SubList>

          <ListItem>12. SONSTIGE BESTIMMUNGEN</ListItem>
          <SubList>
            <SubListItem twoDigitNumber>
              12.1. Sofern einzelne Bestimmungen des Vertrages bzw. dieser Allgemeinen Geschäftsbedingungen ungültig
              sind, hat dies nicht die Ungültigkeit des gesamten Vertrages bzw. der gesamten Allgemeinen
              Geschäftsbedingungen zur Folge. Der restliche Vertragsinhalt bzw. die restlichen Allgemeinen
              Geschäftsbedingungen bleiben unverändert bestehen.
            </SubListItem>
            <SubListItem twoDigitNumber>
              12.2. Erfüllungsort ist die Geschäftsanschrift der Weingut Göschl GesbR, 7122 Gols, Am Kanal 4.
            </SubListItem>
            <SubListItem twoDigitNumber>
              12.3. Zur Entscheidung aller im Zusammenhang mit dem Vertragsverhältnis entstehenden Streitigkeiten ist je
              nach Streitwert das Bezirksgericht Neusiedl am See bzw. das Landesgericht Eisenstadt ausschließlich
              zuständig.
            </SubListItem>
            <SubListItem twoDigitNumber>
              12.4. Der Vertrag unterliegt österreichischem Recht unter Ausschluss des UN-Kaufrechts.
            </SubListItem>
            <SubListItem twoDigitNumber>
              12.5. Für Geschäfte mit Verbrauchern ist Punkt 12.3 nicht anzuwenden. Punkt 12.4 gilt mit der
              Einschränkung, dass unter den Bedingungen des Art 6 der Verordnung (EG) Nr. 593/2008 des Europäischen
              Parlaments und des Rates vom 17. Juni 2008 über das auf vertragliche Schuldverhältnisse abzuwendende Recht
              ("Rom I") besondere Verbraucherschutzbestimmungen des Rechts des Staates, in dem der Verbraucher seinen
              gewöhnlichen Aufenthalt hat, dem gewählten österreichischen Recht vorgehen können.
            </SubListItem>
          </SubList>
        </List>
      </Container>
    </>
  )
}

export default AGB

export const query = graphql`
  query {
    markdownRemark(fileAbsolutePath: { regex: "/preisliste/" }) {
      frontmatter {
        preislistePdf {
          relativePath
        }
      }
    }
  }
`
